import { default as indexE0eU27vCdlMeta } from "/opt/render/project/src/apps/website/pages/index.vue?macro=true";
import { default as privacyO79kMThXR4Meta } from "/opt/render/project/src/apps/website/pages/privacy.vue?macro=true";
import { default as termsueJ0De7AeIMeta } from "/opt/render/project/src/apps/website/pages/terms.vue?macro=true";
export default [
  {
    name: indexE0eU27vCdlMeta?.name ?? "index___bg",
    path: indexE0eU27vCdlMeta?.path ?? "/",
    meta: indexE0eU27vCdlMeta || {},
    alias: indexE0eU27vCdlMeta?.alias || [],
    redirect: indexE0eU27vCdlMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexE0eU27vCdlMeta?.name ?? "index___en",
    path: indexE0eU27vCdlMeta?.path ?? "/en",
    meta: indexE0eU27vCdlMeta || {},
    alias: indexE0eU27vCdlMeta?.alias || [],
    redirect: indexE0eU27vCdlMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacyO79kMThXR4Meta?.name ?? "privacy___bg",
    path: privacyO79kMThXR4Meta?.path ?? "/privacy",
    meta: privacyO79kMThXR4Meta || {},
    alias: privacyO79kMThXR4Meta?.alias || [],
    redirect: privacyO79kMThXR4Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyO79kMThXR4Meta?.name ?? "privacy___en",
    path: privacyO79kMThXR4Meta?.path ?? "/en/privacy",
    meta: privacyO79kMThXR4Meta || {},
    alias: privacyO79kMThXR4Meta?.alias || [],
    redirect: privacyO79kMThXR4Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: termsueJ0De7AeIMeta?.name ?? "terms___bg",
    path: termsueJ0De7AeIMeta?.path ?? "/terms",
    meta: termsueJ0De7AeIMeta || {},
    alias: termsueJ0De7AeIMeta?.alias || [],
    redirect: termsueJ0De7AeIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsueJ0De7AeIMeta?.name ?? "terms___en",
    path: termsueJ0De7AeIMeta?.path ?? "/en/terms",
    meta: termsueJ0De7AeIMeta || {},
    alias: termsueJ0De7AeIMeta?.alias || [],
    redirect: termsueJ0De7AeIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/terms.vue").then(m => m.default || m)
  }
]