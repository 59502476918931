import revive_payload_client_GXnHgItrjI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_90FYMwrGyC from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_y6jqda303u from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_l70kKhHlVY from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/website/.nuxt/components.plugin.mjs";
import prefetch_client_RqFZB1z0Kf from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_QgovlFjV3a from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_S7XSjIsMVN from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_2w7ffxsvttybsvgidlh6tmnrnm/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_QiY79SFQiR from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Cm4hTmS0i9 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_rLZYGQcK3W from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.5_@unocss+reset@_xr4d43xnblqxzfbwijdsiylcpm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/opt/render/project/src/apps/website/plugins/sentry.client.ts";
import plugin_auto_pageviews_client_CRVVMAZpjw from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_GXnHgItrjI,
  unhead_90FYMwrGyC,
  router_y6jqda303u,
  check_outdated_build_client_l70kKhHlVY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_RqFZB1z0Kf,
  plugin_client_QgovlFjV3a,
  plugin_S7XSjIsMVN,
  switch_locale_path_ssr_QiY79SFQiR,
  i18n_Cm4hTmS0i9,
  chunk_reload_client_rLZYGQcK3W,
  sentry_client_shVUlIjFLk,
  plugin_auto_pageviews_client_CRVVMAZpjw
]