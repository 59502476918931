<template>
    <Html :lang="locale" class="h-full scroll-smooth bg-white font-normal" />

    <Body class="h-full" />

    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <AppPrivacyPolicyBanner
        v-if="showPrivacyPolicyBanner"
        @toggle="clickPrivacyPolicyBanner"
    />

    
</template>

<script setup lang="ts">
const { locale, t } = useI18n()

useHead({
    link: [
        { rel: 'canonical', href: 'https://efirma.bg' },
        { rel: 'icon', type: 'image/png', href: '/favicon-32x32.png' },
    ],
})

useSeoMeta({
    title: () => t('meta.title'),
    description: () => t('meta.description'),
    ogUrl: 'https://efirma.bg',
    ogTitle: 'eFirma.bg',
    ogType: 'website',
    ogImage: 'https://efirma.bg/images/og-image.png',
    ogDescription: () => t('meta.description'),
    ogLocale: () => (locale.value === 'bg' ? 'bg_BG' : 'en_US'),
    ogLocaleAlternate: () => (locale.value === 'bg' ? 'en_US' : 'bg_BG'),
    twitterTitle: 'eFirma.bg',
    twitterDescription: () => t('meta.description'),
    twitterImage: 'https://efirma.bg/images/og-image.png',
    twitterCard: 'summary',
})

const isProd = process.env.NODE_ENV === 'production'

const showPrivacyPolicyBanner = ref(false)

const privacyConcentAgreed = useCookie<boolean>(
    'efirma-privacy-agreed',
    // 1 year
    {
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
        domain: isProd ? '.efirma.bg' : 'localhost', // Share across all subdomains
        path: '/', // Accessible site-wide
        secure: isProd, // Only secure in production (HTTPS)
        sameSite: 'lax', // Protect from CSRF attacks
    },
)

onMounted(() => {
    if (privacyConcentAgreed.value === undefined) {
        showPrivacyPolicyBanner.value = true
    }
})

function clickPrivacyPolicyBanner(value: boolean) {
    privacyConcentAgreed.value = value
    showPrivacyPolicyBanner.value = false
}
</script>

<i18n lang="yaml">
en:
    meta:
        title: 'eFirma.bg - Simplified accounting for small businesses'
        description: 'Optimize your operations with eFirma, your comprehensive business management solution. Manage invoices, expenses, contacts, and much more with ease.'

bg:
    meta:
        title: 'eFirma.bg - Улеснено счетоводство за малкия бизнес'
        description: 'Оптимизирайте вашите операции с eFirma, вашето всеобхватно решение за управление на бизнеса. Управлявайте фактури, разходи, контакти и още много други с лекота.'
</i18n>
